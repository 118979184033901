<template>
  <div class="course-type-page">
   <Header/>
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333; cursor: default">课程分类</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="type-box">
      <!-- <div
        class="type-item"
        v-for="more in MoreType"
        :key="more.id"
        @click="$router.push(`/courlist?tid=${more.id}&tn=${more.type_name}`)"
      >
        <img :src="more.pic" alt="" />
        <b>{{ more.type_name }}</b>
      </div> -->
      <div
        class="type-item"
        v-for="more in MoreType"
        :key="more.id"
        @click="typeShow(more)"
      >
        <img :src="more.pic" alt="" />
        <!-- <b>{{ more.type_name }}</b> -->
      </div>

      <!-- 遮罩背景 -->
      <div class="mask" v-if="maskShow"></div>

      <!--点击课程类型弹框 -->
      <div class="courseListShow" v-if="courseListShow">
        <div class="el-icon-close icon-close" @click="typeShowClose">
          <!-- <img style="width:30px;height:30px"  src="../assets/images/close_btn.png" alt=""> -->
        </div>
        <div class="showTop">
          <p style="font-size: 26px; padding: 0 45px">
            {{
              frameList.type_name !== null && frameList.type_name !== " "
                ? frameList.type_name
                : "暂未添加名称"
            }}
          </p>
          <span
            style="
              color: #999;
              padding: 0 45px;
              display: block;
              margin-top: -10px;
            "
            >课程有效期:一年</span
          >
        </div>
        <div class="showItemBox">
          <div class="showItem" v-for="i in courseList">
            <span
              style="
                font-size: 20px;
                margin-left: 0px;
                display: inline-block;
                margin-bottom: 10px;
              "
              >{{ i.c_title }}</span
            >

            <!-- 每一个item-->
            <div style="display: flex">
              <!-- 左侧图片 -->
              <img
                style="width: 131px; height: 100px; margin-top: 4px"
                :src="i.pic"
                alt=""
              />
              <!-- 右侧章节 -->
              <div
                style="
                  font-size: 16px;
                  width: 100%;
                  height: 100%;
                  margin-left: 20px;
                "
              >
                <!-- <p style="margin-left:30px">{{o.v_title}}</p> -->
                <div  style="display:flex" v-for="o in i.video_list">
                  <span style="display: inline-block; margin-bottom: 8px">{{
                    o.number + "、"
                  }}</span
                  ><span
                    style="
                      display: inline-block;
                      margin-top: 0px;
                      margin-bottom: -4px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 180px;
                    "
                    >{{ o.v_title }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 下边购买横条 -->
        <div class="showBottom">
          <span class="bottomLeft" style="color: red; font-weight: 500"
            >{{ frameList.price }}元</span
          >
          <el-button class="bottomRight" type="primary" @click="buy"
            >购买</el-button
          >
        </div>
      </div>

      <!-- 购买弹窗 -->
      <div class="mask-code" v-show="codeShow">
        <div class="code-box">
          <!-- <close-circle-outlined class="wechat-close" @click="closeMask" /> -->
          <i class="el-icon-circle-close icon" @click="closeMaskzhifu"></i>
          <div class="code-img" id="qrcode"></div>
          <p>使用微信扫描二维码进行支付</p>
        </div>
      </div>

      <el-pagination
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        v-if="MoreType && totalNumber > 15"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 登录弹窗 -->
    <div class="login-mask" v-if="loginShow" key="login">
      <i class="el-icon-close" @click="closeLoginMask('loginForm')"></i>
      <span>登录</span>
      <el-form
        ref="loginForm"
        label-width="auto"
        :rules="loginRule"
        :model="loginForm"
      >
        <el-form-item prop="mobile">
          <p slot="label" style="margin: 0"></p>
          <el-input
            placeholder="请输入手机号"
            prefix-icon="el-icon-mobile-phone"
            v-model="loginForm.mobile"
            type="number"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <p slot="label" style="margin: 0"></p>
          <el-input
            type="password"
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            maxlength="16"
            @input="
              loginForm.password = loginForm.password.replace(/[^\w\.\/]/gi, '')
            "
            v-model="loginForm.password"
            :onkeyup="(loginForm.password = loginForm.password.trim())"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click.prevent="loginMethods('loginForm')"
            >登录</el-button
          >
          <el-button @click="closeLoginMask('loginForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <Foot/>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Foot from "../components/Foot.vue";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      totalNumber: 0,
      pageSize: 1,
      MoreType: [],
      currentPage: 1,
      type_id: "",
      frameList: [], //弹框支付课程类型数据
      courseList: [], //弹框支付列表数据
      maskShow: false, //
      courseListShow: false, //点击课程类型弹框
      codeShow: false, //购买弹窗默认不显示、
      interval: null, //判断支付的定时器
      qrcode: "", //
      loginShow: false, //登录弹窗
      loginForm: {
        //登录表单
        mobile: "",
        password: "",
      },
      loginRule: {
        //登陆表单验证
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
      },
    };
  },

  components: { Header,Foot},

  computed: {},
  mounted() {
    this.getMoreType();
  },
  methods: {
    // 点击确定登录事件
    loginMethods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          let { mobile, password } = this.loginForm;
          fd.append("mobile", mobile);
          fd.append("password", password);
          this.axios.post("/login/login", fd).then((res) => {
            if (res.data.code === 0) {
              console.log("成功");
              localStorage.setItem("eft", res.data.data.token);
              this.$message.success(res.data.msg);
              setTimeout(() => {
                this.maskShow = false;
                this.loginShow = false;
                this.$refs[formName].resetFields(); //清空表单
                // this.$router.push("/");
                this.getMoreType(); //登录成功，更新当前页面
                this.$router.go(0);
              }, 800);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    // 关闭登录事件
    closeLoginMask(formName) {
      this.maskShow = false;
      this.loginShow = false;
      this.$refs[formName].resetFields();
    },
    //点击课程类型弹框
    typeShow(type) {
      //  请求一下courselist那个接口，从里边code判断
      //  如果它购买了此课程，就直接点进去，否则就弹框让他购买
      console.log(type);
      this.type_id = type.id;
      let fd = new FormData();
      fd.append("type_id", type.id);
      fd.append("page_size", 1);
      this.axios.post("/index/course_list", fd).then((res) => {
        if (res.data.code == 22) {
          //说明用户此时未登录，弹出登录弹窗
          this.maskShow = true;
          this.loginShow = true;
        } else {
          this.courseList = res.data.course_list;
          this.frameList = res.data.type_info;
          console.log(res.data.code);
          if (res.data.code == 1) {
            //code==1说明他没购买
            this.maskShow = true;
            this.courseListShow = true;
          } else {
            //说明他已购买，直接给它展示课程类型
            this.$router.push(`/courlist?tid=${type.id}&tn=${type.type_name}`);
          }
        }
      });
    },
    //  关闭课程类型弹框
    typeShowClose() {
      this.maskShow = false;
      this.courseListShow = false;
      this.type_id = "";
    },
    // 购买支付
    buy() {
      let fd = new FormData();
      fd.append("type_id", this.type_id);
      fd.append("price", this.frameList.price);
      this.axios.post("/index/pay", fd).then((res) => {
        this.createQRcode(res.data.data); //执行函数让二维码生成链接
        this.listenOrder(res.data.out_trade_no); //监听它是否已经支付
        this.codeShow = true;
      });
    },
    // 用上边返的链接生成二维码
    createQRcode(url) {
      //  let interval, erweima;
      this.qrcode = document.getElementById("qrcode");
      var erweima = new QRCode(document.getElementById("qrcode"), {
        text: url, // 需要转换为二维码的内容
        width: 250,
        height: 250,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      }); // 设置要生成二维码的链接
    },
    // 监听是否支付
    listenOrder(noid) {
      this.selectOrder(noid);
    },
    selectOrder(noid) {
      this.interval = setInterval(async () => {
        let fd = new FormData();
        fd.append("number", noid);
        this.axios.post("/index/selectorder", fd).then((res) => {
          console.log(res);
          console.log(res.data);
          console.log(res.data.trade_state);
          if (res.data.trade_state === "SUCCESS") {
            console.log("成功了");
            clearInterval(this.interval);
            this.closeMaskzhifu(); //二维码清空
            this.courseListShow = false; //支付弹窗清空
            // this.$message.destroy();
            this.$message.success("支付成功!");
            this.maskShow = false;
            // let e = {
            //   dataset: {
            //     type: "show",
            //   },
            // };
            // emit("navChange", e);
            // state.showList();
          }
        });
      }, 2000);
    },
    // 关闭支付
    closeMaskzhifu() {
      this.codeShow = false;
      this.qrcode.innerHTML = "";
      clearInterval(this.interval);
    },
    // 获取更多课程分类
    getMoreType() {
      let fd = new FormData();
      fd.append("page_size", 15);
      this.axios.post("/index/more_course_type", fd).then((res) => {
        console.log(res);
        this.MoreType = res.data.list;
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
      });
    },
    //更多课程分类-更改页码
    handleCurrentChange(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      this.axios.post("/index/more_course_type", fd).then((res) => {
        this.MoreType = res.data.list;
        this.totalNumber = res.data.count;
        this.pageSize = res.data.page_size;
      });
    },
  },
};
</script>
<style lang="scss">
body,
html {
  background: #f8f8f8;
}
.el-input__inner {
  border-radius: 50px;
}
.el-button {
  border-radius: 50px;
  background-color: #8bb9fc;
  border: 0;
  color: #fff;
}
</style>
<style lang="scss" scoped>
.course-type-page {
  background-color: #f8f8f8;
  overflow: hidden;
  .type-box {
    max-width: 1200px;
    height: 100%;
    background-color: #fff;
    margin: 0 auto;
    overflow: hidden;
    padding: 15px 10px;
    box-sizing: border-box;
    margin-bottom: 310px;
    & > div.type-item {
      display: inline-block;
      width: 220px;
      height: 130px;
      margin-right: 8px;
      margin-left: 8px;
      margin-top: 7px;
      margin-bottom: 7px;
      text-align: center;
      position: relative;
      // background-color: #000;
      // // background-position-x: 50% !important;
      // background-position: center center !important;
      // background-size: 100% 100% !important;
      img {
        width: 100%;
        height: 100%;
      //  object-fit: inherit;
      }
      &:last-child {
        margin-right: 0;
      }
      b {
        position: absolute;
        font-size: 26px;
        color: #609bf6;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: default;
      }
    }
  }
  // 登录弹窗
  .login-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    z-index: 999999999;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
  }

  // 遮罩背景
  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.39);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
  }
}

// 支付弹窗
.mask-code {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999999999;
  .code-box {
    width: 380px;
    height: 540px;
    border-radius: 4px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    background: #fff;
    border: 1px solid #e5e5e5;
    padding-top: 1px;
    .code-img {
      width: 250px;
      height: 250px;
      margin: 0 auto;
      margin-top: 20%;
    }
    p {
      font-size: 20px;
      color: #353535;
      text-align: center;
      margin-top: 50px;
    }
    .icon {
      font-size: 20px;
      float: right;
      margin: 15px;
      cursor: pointer;
    }
  }
}
// 点击课程类型的弹框
// 100%
.courseListShow {
  height: 650px;
  width: 25%;
  background: #ffff;
  border: 1px solid #707070;
  border-radius: 8px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 0px;
  box-sizing: border-box;
  z-index: 9999;
  padding-top: 100px;
  .icon-close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 999999;
  }
  .showTop {
    width: 100%;
    height: 125px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .showItemBox {
    width: 100%;
    height: 495px;
    overflow-y: scroll;
    // margin-top:20px;
    margin-top: 5px;
    margin-bottom: 45px;
    .showItem {
      margin-left: 20px;
      margin-right: 20px;
      border-radius: 10px;
      background: #fff;
      padding: 0px 25px;
      margin-top: 9px;
      padding-bottom: 20px;
    }
  }

  .showBottom {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    background: #f1f1f1;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    .bottomLeft {
      font-size: 20px;
      margin-left: 20px;
      margin-top: 10px;
    }
    .bottomRight {
      font-size: 16px;
      height: 70%;
      line-height: 70%;
      margin-top: 8px;
      margin-right: 20px;
      padding-top: 10px;
    }
  }
}
// 125%
@media screen and (max-width: 1540px) and (min-width: 1280px) {
  .courseListShow {
    height: 650px;
    width: 25%;
    background: #ffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 0px;
    box-sizing: border-box;
    z-index: 9999;
    padding-top: 100px;
    .icon-close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 20px;
      right: 10px;
      z-index: 999999;
    }
    .showTop {
      width: 100%;
      height: 125px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .showItemBox {
      width: 100%;
      height: 495px;
      overflow-y: scroll;
      // margin-top:20px;
      margin-top: 5px;
      margin-bottom: 45px;
      .showItem {
        background: #fff;
        padding: 0px 25px;
        padding-bottom: 20px;
      }
    }

    .showBottom {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      background: #f1f1f1;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      .bottomLeft {
        font-size: 20px;
        margin-left: 20px;
        margin-top: 10px;
      }
      .bottomRight {
        font-size: 16px;
        height: 70%;
        line-height: 70%;
        margin-top: 8px;
        margin-right: 20px;
        padding-top: 10px;
      }
    }
  }
}
// 150%(不管用)
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .courseListShow {
    height: 650px;
    width: 25%;
    background: #ffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 0px;
    box-sizing: border-box;
    z-index: 9999;
    padding-top: 100px;
    .icon-close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 20px;
      right: 10px;
      z-index: 999999;
    }
    .showTop {
      width: 100%;
      height: 125px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .showItemBox {
      width: 100%;
      height: 495px;
      overflow-y: scroll;
      // margin-top:20px;
      margin-top: 5px;
      margin-bottom: 45px;
      .showItem {
        background: #fff;
        padding: 0px 25px;
        padding-bottom: 20px;
      }
    }

    .showBottom {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      background: #f1f1f1;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      .bottomLeft {
        font-size: 20px;
        margin-left: 20px;
        margin-top: 10px;
      }
      .bottomRight {
        font-size: 16px;
        height: 70%;
        line-height: 70%;
        margin-top: 8px;
        margin-right: 20px;
        padding-top: 10px;
      }
    }
  }
}
// 1366、150%
@media screen and (max-width: 1380px) and (min-width: 1280px) {
  .courseListShow {
    height: 650px;
    width: 25%;
    background: #ffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 0px;
    box-sizing: border-box;
    z-index: 9999;
    padding-top: 100px;
    .icon-close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 20px;
      right: 10px;
      z-index: 999999;
    }
    .showTop {
      width: 100%;
      height: 125px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .showItemBox {
      width: 100%;
      height: 495px;
      overflow-y: scroll;
      // margin-top:20px;
      margin-top: 5px;
      margin-bottom: 45px;
      .showItem {
        background: #fff;
        padding: 0px 25px;
        padding-bottom: 20px;
      }
    }

    .showBottom {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      background: #f1f1f1;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      .bottomLeft {
        font-size: 20px;
        margin-left: 20px;
        margin-top: 10px;
      }
      .bottomRight {
        font-size: 16px;
        height: 70%;
        line-height: 70%;
        margin-top: 8px;
        margin-right: 20px;
        padding-top: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.course-type-page {
  .el-pagination {
    text-align: center;
    margin-top: 30px;
    .btn-prev,
    .btn-next {
      background-color: #fff !important;
      border: 1px solid #66a4ff;
      border-radius: 3px !important;
      padding: 5px 10px !important;
      box-sizing: border-box !important;
      &:hover {
        color: #66a4ff;
      }
      span {
        line-height: 1;
      }
    }
    .el-pager {
      li {
        font-weight: normal;
      }
    }
  }
}
</style>
