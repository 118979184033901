import { render, staticRenderFns } from "./CourseType.vue?vue&type=template&id=04ec8bfc&scoped=true&"
import script from "./CourseType.vue?vue&type=script&lang=js&"
export * from "./CourseType.vue?vue&type=script&lang=js&"
import style0 from "./CourseType.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CourseType.vue?vue&type=style&index=1&id=04ec8bfc&lang=scss&scoped=true&"
import style2 from "./CourseType.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04ec8bfc",
  null
  
)

export default component.exports